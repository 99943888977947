import type { Api } from '../public/public-api';
import { loadScriptWithGlobal } from 'src/util/script-loader';
import { proxyEventsToJQuery } from './proxy-form-events-to-jquery';

/**
 * The compatibility API that is exposed by the legacy session script for Vue forms compatibility
 */
export type LegacySeamlessEmbedCompatibilityApi = {
	deferredLoad?(api: LegacySeamlessEmbedApi): void;
};

/**
 * An API exposed by the Vue forms client for loading a form with legacy embed code
 */
export class SeamlessEmbedCompatibilityApi implements LegacySeamlessEmbedApi {
	readonly api: Api;
	constructor(api: Api) {
		Object.defineProperty(this, 'api', { configurable: false, enumerable: false, writable: false, value: api });
	}

	load(module: LegacySeamlessEmbedModule, args: LegacySeamlessEmbedLoadArgs, options: LegacySeamlessEmbedLoadOptions = null) {
		if (module === 'forms' && args && args.id) {
			// Determine the mount target, either the specified selector, or the default '.cognito' div
			let target = options ? options.target || '' : '';
			if (target === '')
				target = '.cognito';

			// Mount the form and get the resulting form handle
			const form = this.api.mount(args.id, target);

			for (const resourceName in window.Cognito.resources) {
				form.overrideText(resourceName, window.Cognito.resources[resourceName]);
			}

			// If there is an "entry" object, then call prefill on the form handle
			if (args.entry)
				form.prefill(args.entry);

			// If a 'success' callback is specified, then call it when the form is ready
			if (options && options.success) {
				const jqPromise = loadScriptWithGlobal('//code.jquery.com/jquery-1.7.2.min.js', 'jQuery');
				form.ready.then(() => {
					jqPromise.then((jQuery: JQueryStatic) => {
						// Relinquish control of the global `$` in case the hosting page has already loaded jQuery
						jQuery.noConflict(true);

						// Put our version of jQuery on the window object as `ExoJQuery`, since that is what the documentation advertises
						window['ExoJQuery'] = jQuery;

						// Proxy form events to the hosting page as jQuery events
						proxyEventsToJQuery(form, jQuery, true);

						options.success();
					});
				});
			}
		}
		else {
			if (window.console && console.warn) {
				console.warn('Invalid call to Cognito.load');
			}
		}
	}
}
